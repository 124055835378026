$blue-grey:   #1a1d2c;
$lt-green:    #65c049;
$text-color:  #e1e1e1;

$footer-height: 30px;

html {
  box-sizing: border-box;
  margin: 0;
}

body {
  background: $blue-grey !important;
  height: 100%;
  position: relative;
}

h1 h2 h3 h4 h5 h6 {
  color: $text-color;
}

.main-container {
  min-height: 100vh;
  overflow: hidden;
  display: block;
  position: relative;
  padding-top : 10px;
  padding-bottom: $footer-height;
}

.headings {
  margin-top: 50px;
  user-select: none;
}

.section-heading {
  padding-top: 10px;
  padding-bottom: 10px;
  color: #ccc;
  text-align: center;
}

.menuItem {
  color: #e1e1e1 !important;
  font-weight: bold !important;

  &:hover {
    color: white !important;
  }
}

.feature-item {
  margin-top: 10px;
  border: 2px solid #333;
  border-radius: 8px;
  height: 200px;
}

.client-logos {
  margin: 0 10px;
}

.image-style {
  border: 2px solid #333;
  border-radius: 12px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, .3);

  &:hover{
    border: 2px solid #fff;
  }
}

.intro-style {
  border: 2px solid #333;
  border-radius: 16px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, .3);
}

.footer {
  position: absolute;
  height: $footer-height;
  width: 100%;
  bottom: 0;
  text-align: center;
  line-height: $footer-height;
  color: #555;
  user-select: none;
}

.carousel {
  border: 2px solid #333;
  border-radius: 16px !important;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, .3) !important;
}
